import React, { useEffect } from "react";
import "./Hero.css";
import saturn from "./assets/logo.png";

const Hero = () => {
  useEffect(() => {
    const createStars = () => {
      const starsContainer = document.querySelector(".hero");
      if (!starsContainer) return;

      const star = document.createElement("div");
      star.classList.add("star");

      const size = Math.random() * 3 + 1;
      const posX = Math.random() * window.innerWidth;
      const posY = Math.random() * window.innerHeight;
      const animationDuration = Math.random() * 2 + 1 + "s";

      star.style.width = `${size}px`;
      star.style.height = `${size}px`;
      star.style.top = `${posY}px`;
      star.style.left = `${posX}px`;
      star.style.animationDuration = animationDuration;

      starsContainer.appendChild(star);
    };

    for (let i = 0; i < 100; i++) {
      createStars();
    }

    const interval = setInterval(createStars, 400);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="hero">
      <div className="hero-content">
        {/* Left Side: Text */}
        <div className="hero-text">
          <h1>ZOHAL</h1>
          <p className="hero-subtitle">Decentralized Perpetual Exchange</p>
          <div className="trading-features">
            <div className="feature-tag">DOG TO THE MOON</div>
            <div className="feature-tag">BTC</div>
            <div className="feature-tag">Native Bitcoin Assets</div>
            <div className="feature-highlight">25x Leverage</div>
          </div>
          <p className="hero-description">
            Trade directly from your wallet with powerful leverage options
          </p>
          <a
            href="https://app.zohal.xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="hero-cta"
          >
            Launch App
          </a>
        </div>

        <div className="hero-animation">
          <img src={saturn} alt="Saturn Logo" className="saturn-logo" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
