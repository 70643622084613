import React, { useEffect } from "react";
import "./Feature.css";
import { motion } from "framer-motion";

const Feature = () => {
  useEffect(() => {
    const createStars = () => {
      const section = document.querySelector(".features-section");
      // Create 50 stars
      for (let i = 0; i < 50; i++) {
        const star = document.createElement("div");
        star.className = "star";
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        section.appendChild(star);
      }
    };

    createStars();
  }, []);

  const features = [
    {
      icon: "📈",
      title: "Seamless Trading",
      description:
        "Experience secure trading with reduced costs and maximum efficiency",
    },
    {
      icon: "💎",
      title: "Lowest Fees",
      description:
        "Maximize your earnings with industry-leading low trading fees",
    },
    {
      icon: "🔒",
      title: "Crypto Collateral",
      description: "Trade freely using your crypto assets as collateral",
    },
    {
      icon: "⚡",
      title: "Advanced Orders",
      description:
        "Full suite of order types including limit, stop, and take-profit",
    },
    {
      icon: "⚖️",
      title: "Dynamic Funding",
      description: "Fair funding rates based on open interest imbalance",
    },
    {
      icon: "🛡️",
      title: "Secure Liquidations",
      description:
        "Multi-source price feeds protect positions from temporary wicks",
    },
  ];

  return (
    <section className="features-section">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="features-header"
      >
        <h2>Why Choose Us</h2>
        <p className="features-subtitle">
          Next-Generation Trading Platform Built for You
        </p>
      </motion.div>

      <div className="features-container">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="feature-card"
          >
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Feature;
