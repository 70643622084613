import React from "react";
import "./Header.css";
import logo from "./assets/logo.png"; 

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        {/* Logo Section */}
        <div className="logo">
          <img src={logo} alt="Zohal Logo" />
          <span className="logo-text">Zohal</span>
        </div>

        {/* Navigation Buttons */}
        <div className="nav-buttons">
          <a
            href="https://docs.zohal.xyz/docs/intro"
            target="_blank"
            rel="noopener noreferrer"
            className="docs-btn"
          >
            Docs
          </a>
          <a
            href="https://app.zohal.xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="start-btn"
          >
            Start Trading →
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
