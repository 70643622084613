import React, { useEffect } from "react";
import "./Ecosystem.css";
import { motion } from "framer-motion";
import starknetLogo from "./assets/icons/starknet.png";
import pragmaLogo from "./assets/icons/pragma.png";
import starkwareLogo from "./assets/icons/starkware.png";

const Ecosystem = () => {
  useEffect(() => {
    const createStars = () => {
      const section = document.querySelector(".ecosystem-section");
      // Create 50 stars
      for (let i = 0; i < 50; i++) {
        const star = document.createElement("div");
        star.className = "star";
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        section.appendChild(star);
      }
    };

    createStars();
  }, []);

  return (
    <section className="ecosystem-section">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="ecosystem-header"
      >
        <h2>Powered by the Best</h2>
        <p className="ecosystem-subtitle">
          Built on industry-leading blockchain infrastructure
        </p>
      </motion.div>

      <motion.div
        className="ecosystem-partners"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {[starkwareLogo, starknetLogo, pragmaLogo].map((logo, index) => (
          <motion.div
            key={index}
            className="partner-card"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img src={logo} alt="Partner Logo" className="partner-logo" />
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="cta-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <h3>Ready to Start Trading?</h3>
        <p>Join thousands of traders on our platform</p>
        <motion.a
          href="https://app.zohal.xyz"
          target="_blank"
          rel="noopener noreferrer"
          className="cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Launch App →
        </motion.a>
      </motion.div>
    </section>
  );
};

export default Ecosystem;
